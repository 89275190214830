<template>
  <div>
    <header class="header">
      <div class="header-text">{{ statusMessage }}</div>
      <!-- <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div> -->
    </header>
    <div class="content">
      <div class="warp" v-if="payOrderInfo.ext">
        <div class="label">用户姓名：</div>
        <div class="value">{{ payOrderInfo.ext.name }}</div>
      </div>
      <div class="warp">
        <div class="label">商品描述：</div>
        <div class="value">{{ payOrderInfo.subject }}</div>
      </div>
      <div class="warp">
        <div class="label">订单号：</div>
        <div class="value">{{ payOrderInfo.mchOrderNo }}</div>
      </div>
    </div>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>

      <!-- 手写输入框 -->
      <div class="input-c">
        <div class="input-c-div-1">{{ payOrderInfo.amount }}</div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>
    </div>
    <div class="bnt-pay">
      <div v-if="payOrderInfo.returnUrl" class="bnt-pay-text" style="background-color: #07c160;" @click="finish">
        完成
      </div>
      <div v-if="!payOrderInfo.returnUrl" class="bnt-pay-text" style="background-color: #07c160;" @click="close">
        关闭
      </div>
    </div>
  </div>
</template>

<script>
import { getPayOrderInfo } from "@/api/api";
import config from "@/config";
import wayCodeUtils from "@/utils/wayCode";
export default {
  data: function () {
    return {
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      amount: 1, // 支付金额默认
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      statusMessage: "",
    };
  },

  mounted() {
    this.setPayOrderInfo(); //获取订单信息 & 调起支付插件
  },

  methods: {
    setPayOrderInfo() {
      this.$loading.show({
        backgroundColor: "rgb(237,237,237)",
      });
      getPayOrderInfo(this.$route.query.payOrderNo)
        .then((res) => {
          this.payOrderInfo = res.data;

          switch (res.data.status) {
            case 1:
              this.statusMessage = "未支付";
              break;
            case 2:
              this.statusMessage = "已支付";
              break;
            case 3:
              this.statusMessage = "支付失败";
              break;
          }
        })
        .catch((err) => {
          this.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },
    finish() {
      if (wayCodeUtils.getPayWay().mini) {
        const url = this.payOrderInfo.returnUrl
        // 解析 URL
        const urlObject = new URL(url);
        // 获取域名后面的路径部分（包括斜杠）
        let pathAfterDomain = urlObject.pathname;
        // 获取路径后面的参数部分（包括问号）
        const queryString = urlObject.search;
        // 如果路径以 /pages 开头，则去掉这部分
        if (pathAfterDomain.startsWith("/pages")) {
          pathAfterDomain = pathAfterDomain.slice("/pages".length);
        }
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `..${pathAfterDomain}${queryString}`
        })
      } else {
        location.href = this.payOrderInfo.returnUrl
      }
    },
    close() {
      if (wayCodeUtils.getPayWay().mini) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateBack()
      } else {
        window.WeixinJSBridge.call("closeWindow");
      }
    },
  },
};
</script>
<style lang="css" scoped>
@import "./pay.css";
</style>
